import React from 'react'
import styled from 'styled-components'

import PhoneImage from 'assets/phone.png'

export const Phone = styled.div`
  background-image: url(${PhoneImage});
  background-size: cover;
  overflow-x: hidden;
  overflow-y: ${p => p.image ? 'hidden' : 'scroll'};
`

function PhoneOutline ({ style, src = 'https://google.com', image = true, invisible = false, allowInteraction = false, width = '300px', height = '600px' }) {
  return (
    <Phone image={image} style={{ width, height, ...style }}>
      {!invisible && image ? (
        <img alt="iphone outline" src={src} style={{width: '100%', padding: '13px', clipPath: 'inset(13px 12px 30px 13px round 29px)'}} />
      ) : (
        <iframe title="iphone demo" src={src} width={width} height={height} style={{clipPath: 'inset(13px 13px 11px 14px round 29px)'}} />
      )}
    </Phone>
  )
}

export default PhoneOutline