import React from 'react'

export default function Logo ({ style, textColor = 'black' }) {
  return (
    <svg width="300px" height="100px" style={style} alt="Divvit Logo" viewBox="0 0 2706 800" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <g id="New-Group">
        <g id="green">
        <path id="top" d="M630.455 326.741 L308.373 648.818 C305.503 651.687 302.359 654.268 298.985 656.521 295.611 658.776 292.023 660.694 288.274 662.246 284.525 663.798 280.633 664.979 276.653 665.77 272.673 666.562 268.625 666.961 264.567 666.961 230.357 666.961 202.619 639.226 202.619 605.013 L202.619 159.972 C202.619 125.748 230.367 98.008 264.59 98.021 L381.679 98.062 C383.628 98.021 385.582 98 387.539 98 490.872 98 581.342 153.097 631.161 235.516 635.149 242.108 638.872 248.869 642.326 255.798 654.117 279.458 649.139 308.051 630.45 326.741" fill="#5dd884" fillOpacity="1" stroke="none"/>
        <path id="bottom" d="M635.876 399.462 C646.616 566.051 515.949 702.52 349.018 702.52 L229.166 702.52 C194.88 702.52 167.068 674.715 167.068 640.42 L167.068 398.132 C167.068 394.129 167.461 390.135 168.242 386.209 169.023 382.286 170.188 378.445 171.72 374.748 173.251 371.049 175.143 367.51 177.366 364.182 179.59 360.854 182.135 357.753 184.966 354.922 L377.415 162.467 C381.416 158.467 385.948 155.036 390.885 152.272 395.821 149.508 401.116 147.438 406.617 146.119 412.119 144.8 417.777 144.245 423.43 144.469 429.083 144.694 434.677 145.697 440.057 147.448 442.682 148.302 445.293 149.195 447.891 150.125 450.492 151.056 453.076 152.025 455.646 153.031 458.216 154.037 460.77 155.082 463.312 156.163 465.851 157.244 468.375 158.363 470.881 159.518 563.297 202.089 628.996 292.765 635.876 399.462" fill="#00794b" fillOpacity="1" stroke="none"/>
        <path id="middle" d="M623.126 333.498 C623.126 333.498 600.623 249.936 530.582 196.613 469.952 150.455 414.477 127.444 382.612 157.946 344.97 193.978 202.677 337.366 202.677 337.366 202.677 337.366 202.679 602.882 202.679 602.882 202.679 602.882 200.63 649.108 243.41 662.945 286.094 676.751 310.949 646.19 310.949 646.19 310.949 646.19 623.126 333.498 623.126 333.498 Z" fill="#009f63" fillOpacity="1" stroke="none"/>
        <path id="Ellipse" d="M282.716 610.323 C282.716 598.806 273.379 589.469 261.861 589.469 250.344 589.469 241.007 598.806 241.007 610.323 241.007 621.841 250.344 631.178 261.861 631.178 273.379 631.178 282.716 621.841 282.716 610.323 Z" fill="#00794b" fillOpacity="1" stroke="none"/>
        </g>
        <g id="black-text">
        <path id="Path" d="M838.799 533.9 L895.598 267.736 951.116 267.736 894.317 533.9 Z" fill={textColor} fillOpacity="1" stroke="none"/>
        <path id="Path-1" d="M1328.211 267.736 L1385.2 267.736 1294.661 533.9 1233.119 533.9 1142.823 267.736 1199.812 267.736 1249.877 426.119 Q1254.049 440.141 1258.532 458.803 1263.016 477.465 1264.089 484.77 1266.08 468.047 1277.748 426.154 Z" fill={textColor} fillOpacity="1" stroke="none"/>
        <path id="Path-2" d="M1764.182 267.736 L1821.154 267.736 1730.685 533.9 1669.143 533.9 1578.846 267.736 1635.835 267.736 1685.9 426.119 Q1690.089 440.141 1694.556 458.803 1699.022 477.465 1700.113 484.77 1702.104 468.047 1713.771 426.154 Z" fill={textColor} fillOpacity="1" stroke="none"/>
        <path id="Path-3" d="M2024.512 533.9 L2081.259 267.736 2136.794 267.736 2080.047 533.9 Z" fill={textColor} fillOpacity="1" stroke="none"/>
        <path id="Path-4" d="M2464.725 533.9 L2408.289 533.9 2408.289 314.702 2336.014 314.702 2336.014 267.736 2537 267.736 2537 314.702 2464.725 314.702 Z" fill={textColor} fillOpacity="1" stroke="none"/>
        </g>
      </g>
      </svg>
  )
}