import React from 'react'
import styled from 'styled-components'
import PageLayout from './PageLayout'

import coursebg from 'assets/courses.png'
import AppHomePage from 'assets/app-home.png'
import AppStatPage from 'assets/app-stats.png'
import DownloadIos from 'assets/download-ios.svg'
import DownloadAndroid from 'assets/download-android.png'

import PhoneOutline from 'components/shared/PhoneOutline'

const PageSection = styled.div`
  width: 100%;
  display: flex;
  background: ${p => p.color ? p.color : `url(${p.backgroundImage})`};
  background-size: cover;
  padding: 100px 0;
  min-height: ${p => p.height || '700px'};
  flex-direction: ${p => p.flexDirection || 'column'};
  align-items: center;
  justify-content: center;

  @media (max-width: 825px) {
    flex-direction: column-reverse;
  }
`

const PageWidth = styled.div`
  display: flex;
  flex-direction: ${p => p.mobileFlexDir || 'column-reverse'};
  justify-content: center;
  align-items: center;
  max-width: 1000px;

  @media (min-width: 825px) {
    flex-direction: row;
  }
`

const AttentionGrabber = styled.h2`
  margin: 0;
  color: white;
  font-weight: 600;
`

const TextWrapper = styled.div`
  margin: 0 5rem 0 5rem;

  @media (max-width: 825px) {
    text-align: center;
  }
`

const FoldText = styled.div`
  margin: 25px;

  @media (max-width: 825px) {
    text-align: center;
  }
`

const MediumHeader = styled.h2`
  color: ${p => p.color};
  margin: 1rem 0;
  font-size: 60px;
  font-weight: 600;
`

const HeaderSubtext = styled.p`
  color: ${p => p.color};
  font-size: 20px;
  line-height: 1.45;
`

class Home extends React.Component {
  render () {
    return (
      <PageLayout logoTextColor={'white'} absoluteNav={true}>
        <PageSection height={'1000px'} flexDirection={'row'} backgroundImage={coursebg}>
          <PhoneOutline image={true} src={AppHomePage}></PhoneOutline>
          <FoldText>
            <AttentionGrabber style={{fontSize: '80px'}}>Tee Off,</AttentionGrabber>
            <AttentionGrabber style={{fontSize: '80px'}}>Track On.</AttentionGrabber>
            <div style={{margin: '20px 0'}}>
              <a href="https://apps.apple.com/us/app/divvit-golf/id1571822903"><img alt="download ios app" style={{margin: '10px'}} src={DownloadIos}/></a>
              <a href="https://play.google.com/store/apps/details?id=com.overlaylabs.divvit&hl=en_US&gl=US"><img alt="download android app" style={{height: '58px'}} src={DownloadAndroid}/></a>
            </div>
          </FoldText>
        </PageSection>
        <PageSection color={'#fff'}>
          <PageWidth mobileFlexDir={'column'}>
            <TextWrapper>
              <MediumHeader color={'#000'}>Wanna Get Better?</MediumHeader>
              <HeaderSubtext color={'#000'}>With Divvit, you can easily track your play history to get a better understanding of how you play and where you can improve.</HeaderSubtext>
            </TextWrapper>
            <div>
              <PhoneOutline image={true} src={AppStatPage}></PhoneOutline>
            </div>
          </PageWidth>
        </PageSection>
        <PageSection color={'#fff'}>
          <PageWidth>
            <TextWrapper>
              <MediumHeader color={'#000'}>Get Social!</MediumHeader>
              <HeaderSubtext color={'#000'}>Follow us on social media to get the latest updates as we make Divvit amazing.</HeaderSubtext>
            </TextWrapper>
          </PageWidth>
        </PageSection>
      </PageLayout>
    )
  }
}

export default Home

