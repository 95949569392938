import React from 'react'
import styled from 'styled-components'

import InstagramIcon from 'svgs/Instagram.js'
import FacebookIcon from 'svgs/Facebook.js'
import TwitterIcon from 'svgs/Twitter.js'

const FooterContainer = styled.div`
  text-align: center;
  margin-top: 60px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FooterLinks = styled.div`
  width: 300px;
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  color: #00a062;
  font-size: 0.8rem;
`

const FooterLink = styled.a`
  color: #00a062;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;

  &:visited {
    color: #00a062;
  }
  &:hover {
    color: #00a062;
  }
`

const SocialIcons = styled.div`
  margin-top: 2rem;
`

const CopyRight = styled.div`
  width: 300px;
  text-align: center;
  color: #6b6b6b;
  font-size: 0.8rem;
`

export default function Footer() {  
  return (
    <FooterContainer>
      <SocialIcons>
        <a href="https://instagram.com/divvitco">
          <InstagramIcon />
        </a>
        <a href="https://twitter.com/divvitco">
          <TwitterIcon />
        </a>
        <a href="https://facebook.com/divvitco">
          <FacebookIcon />
        </a>
      </SocialIcons>
      <FooterLinks>
        <span><FooterLink href="/privacy-policy">Privacy Policy</FooterLink></span>
        <span><FooterLink href="/terms-of-use">Terms</FooterLink></span>
        <span><FooterLink href="/contact">Contact</FooterLink></span>
      </FooterLinks>
      <CopyRight>
        <span>&copy; {new Date().getFullYear()} Overlay Labs LLC</span>
      </CopyRight>
    </FooterContainer>
  )
}
