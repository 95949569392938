import React from 'react'
import styled from 'styled-components'

import Navbar from 'components/web/Navbar'
import Footer from 'components/web/Footer'

const PageTheme = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

class PageLayout extends React.Component {
  render () {
    const { children, logoTextColor, absoluteNav } = this.props

    return (
      <PageTheme>
        <Navbar logoTextColor={logoTextColor} absoluteNav={absoluteNav}/>
          {children}
        <Footer />
      </PageTheme>
    )
  }
}

export default PageLayout

