import React from 'react'
import styled from 'styled-components'

import Logo from 'components/shared/Logo.js'

const NavContainer = styled.div`
  display: flex;
  height: 60px;
  margin: 50px 0;
  width: 100%;
  max-width: 788px;
  align-items: center;
  justify-content: center;
  ${p => p.absolute ? 'position: absolute;' : ''}
`

// const NavItem = styled.a`
//   text-transform: uppercase;
//   text-decoration: none;
//   margin: 0 25px;
//   color: #fff;
//   font-weight: 600;
//   letter-spacing: 0.2em;
//   font-size: 0.8rem;

//   &:visited {
//     color: #fff;
//   }
// `

class Navbar extends React.Component {
  render () {
    const { absoluteNav, logoTextColor } = this.props

    return (
      <NavContainer absolute={absoluteNav}>
        <a href="https://divvit.co">
          <Logo textColor={logoTextColor} style={{height: '35px'}} />
        </a>
        {/* <div>
          <NavItem href="">Events</NavItem>
          <NavItem href="">Friends</NavItem>
          <NavItem href="">Personal</NavItem>
        </div> */}
      </NavContainer>
    )
  }
}

Navbar.defaultProps = {
  logoTextColor: 'black'
}

export default Navbar