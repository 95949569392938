import React from 'react'
import styled from 'styled-components'
import PageLayout from './PageLayout'

const Title = styled.h2`
  margin: 30px 0;
  font-size: 2rem;
`

const Paragraph = styled.div`
  width: 300px;
  text-align: center;
  line-height: 1.5rem;
`

class Contact extends React.Component {
  render () {
    return (
      <PageLayout>
        <Title>Contact Us</Title>
        <Paragraph>
          Hey! If you have questions, need help or would like to suggest a feature or improvement, don't be shy! You can shoot us an email at <a href="mailto:support@divvit.co">support@divvit.co</a>.
        </Paragraph>
      </PageLayout>
    )
  }
}

export default Contact

