import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'

import Home from './Home'
import Privacy from './Privacy'
import Terms from './Terms'
import Contact from './Contact'
 
class WebRouter extends React.Component {
  render () {
    return (
      <Router>
        <Switch>
          <Route path="/" exact={true} render={() => <Home />} />
          <Route path="/privacy-policy" exact={true} render={() => <Privacy />} />
          <Route path="/terms-of-use" exact={true} render={() => <Terms />} />
          <Route path="/contact" exact={true} render={() => <Contact />} />
        </Switch>
      </Router>
    )
  }
}

export default WebRouter