import React from 'react';
import './App.css';

// routers
import WebRouter from './components/web/Router'

class App extends React.Component {

  componentDidMount() {
    //document.body.style.overflow = "hidden"
  }

  render () {
    return (
      <div className="theme-wrapper bp4-dark">
        <WebRouter />
      </div>
    )
  }
}

export default App